import React from 'react';
import Box from "@mui/material/Box"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import preview1 from "../../Images/app-img/preview-1.png";
import preview2 from "../../Images/app-img/preview-2.png";
import { SectionTitle } from '../../Component/Common/Common';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./Gallery.css"

const Gallery = () => {

    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
    const matchMd = useMediaQuery(theme.breakpoints.down("md"));

    const settings = {
        dots: true,
        speed: 500,
        infinite: true,
        slidesToShow: matchSm ? 1 : matchMd ? 5 : 5,
        centerMode: false,
    };

    return (
        <Box className="section" id="gallery">
            <SectionTitle
                heading="Gallery"
                description="Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas."
            />
            <Box className="app-images">
                <Slider {...settings}>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview1} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview2} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview1} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview2} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview1} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview2} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview1} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview2} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview1} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                    <Box sx={{ p: 2, outline: "none", cursor: "pointer" }}>
                        <img src={preview2} alt='Mobile preview' width="100%" style={{ border: "6px solid #c5974e", padding: "3px", borderRadius: "20px" }} />
                    </Box>
                </Slider>
            </Box>
        </Box>
    )
}

export default Gallery