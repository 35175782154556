import React from 'react'
import Toolbar from '@mui/material/Toolbar';
import Hero from '../../Section/Hero/Hero';
import Features from '../../Section/Features/Features';
import FAQ from '../../Section/FAQ/FAQ';
import Testimonials from '../../Section/Testimonials/Testimonials';
import Gallery from '../../Section/Gallery/Gallery';
import Contact from '../../Section/Contact/Contact';
import Count from '../../Component/Count/Count';
import Box from "@mui/material/Box"
import './Home.css'
import Container from '@mui/material/Container';

const Home = () => {

    const counts = [
        {
            id: "001",
            label: "User",
            number: "166",
            duration: "2",
        },
        {
            id: "002",
            label: "Download",
            number: "5321",
            duration: "2",
        },
        {
            id: "003",
            label: "Installs within 2 years",
            number: "100",
            duration: "2",
        },
        {
            id: "004",
            label: "Client",
            number: "0",
            duration: "2",
        },
    ];

    return (
        <React.Fragment>
            <Toolbar />
            <Hero />
            <Features />
            <Gallery />
            <FAQ />
            <Testimonials />
            <Box className="section">
                <Container>
                    <Box className="counting">
                        {counts.map((count) => (
                            <Count key={count.id} data={count} />
                        ))}
                    </Box>
                </Container>
            </Box>
            <Contact />
        </React.Fragment>
    )
}

export default Home