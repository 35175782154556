import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { Description, Heading, SectionTitle } from '../../Component/Common/Common';
import "./Contact.css"

const Contact = () => {

    const grey = {
        50: '#f6f8fa',
        100: '#eaeef2',
        200: '#d0d7de',
        300: '#afb8c1',
        400: '#8c959f',
        500: '#6e7781',
        600: '#57606a',
        700: '#424a53',
        800: '#32383f',
        900: '#24292f',
    };

    const StyledTextarea = styled(TextareaAutosize)(
        ({ theme }) => `
        width: 100%;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
            };
      `,
    );

    return (
        <Box className="section-bg contact" id="contact">
            <Container sx={{ p: { xs: 0 } }}>
                <SectionTitle
                    heading="Contact us"
                    description="Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas."
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box className="contant-info">
                            <Box sx={{ display: "flex" }}>
                                <LocationOnIcon className='info-icon' />
                                <Box sx={{ ml: 2 }}>
                                    <Heading heading="Location :-" css={{ p: 0 }} />
                                    <Description description="A108 Adam Street, New York, NY 535022" />
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-between", my: 3 }}>
                                <Box sx={{ display: "flex", my: { xs: 3, sm: 0 } }}>
                                    <EmailIcon className='info-icon' />
                                    <Box sx={{ ml: 2 }}>
                                        <Heading heading="Email :-" css={{ p: 0 }} />
                                        <Description description="info@example.com" />
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                    <CallIcon className='info-icon' />
                                    <Box sx={{ ml: 2 }}>
                                        <Heading heading="Call :-" css={{ p: 0 }} />
                                        <Description description="+971 99999 99999" />
                                    </Box>
                                </Box>
                            </Box>
                            <iframe style={{ border: "none" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7461135.312978306!2d44.34230312351643!3d24.0616389235679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e48dfb1ab12bd%3A0x33d32f56c0080aa7!2sUnited%20Arab%20Emirates!5e0!3m2!1sen!2sus!4v1685946385650!5m2!1sen!2sus" width="100%" height="310" loading="lazy"></iframe>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ p: 2 }}>
                        <Box sx={{ p: 4, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: "5px", bgcolor: "#fff" }}>
                            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: "space-around", alignItems: "center", mb: 2 }}>
                                <TextField id="outlined-basic" label="Your Name" variant="outlined" sx={{ width: "100%", mr: 1 }} />
                                <TextField id="outlined-basic" label="Your Email" variant="outlined" sx={{ width: "100%", ml: { sm: 1 }, mt: { xs: 2 } }} />
                            </Box>
                            <TextField id="outlined-basic" label="Subject" variant="outlined" sx={{ width: "100%", my: { xs: 2, sm: 2 } }} />
                            <StyledTextarea aria-label="empty textarea" placeholder="Message" style={{ height: "236px", outline: "none" }} />
                            <Box sx={{ textAlign: "center", mt: 2 }}>
                                <Button variant="contained" className='send-msg-btn'>Send Message</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default Contact