import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Heading, SectionTitle } from '../../Component/Common/Common';
import Carousel from "react-material-ui-carousel";
import './Testimonials.css'

const Testimonials = () => {

    var items = [
        {
            name: "Awesome functionality!",
            city: "Mumbai",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, and numquam aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi, provident voluptates consectetur maiores quos.",
        },
        {
            name: "Rohit W",
            city: "Mumbai",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur,and numquam aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi, provident voluptates consectetur maiores quos.",
        },
        {
            name: "Punit Parmar",
            city: "Mumbai",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, and numquam aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi, provident voluptates consectetur maiores quos.",
        },
        {
            name: "Shubham",
            city: "Mumbai",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, and numquam aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi, provident voluptates consectetur maiores quos.",
        },
        {
            name: "Nikhil",
            city: "Mumbai",
            description:
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus pariatur, and numquam aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae deleniti minus animi, provident voluptates consectetur maiores quos.",
        },
    ];

    return (
        <Box className="section testimonial" id="testimonial">
            <SectionTitle
                heading="Testimonials"
                description="Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas."
            />
            <Box className="testimonial-container">
                <Carousel
                    className="testimonial"
                    navButtonsAlwaysVisible={true}
                    indicators={true}
                    animation="slide"
                >
                    {items.map((row, id) => (
                        <Box key={id} className="testimonial-card">
                            <Heading heading={row.name} />
                            <Typography className='testimonial-user-loaction'> {row.city} </Typography>
                            <Typography className='description'> <FormatQuoteIcon />  {row.description} <FormatQuoteIcon /></Typography>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Box>
    )
}

export default Testimonials