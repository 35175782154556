import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Logo from "./../../Images/par-logo.png";
import { NavHashLink } from 'react-router-hash-link';
import './Header.css'

const drawerWidth = 240;
const navItems = [
    { title: 'Home', path: '#hero' },
    { title: 'App Features', path: '#features' },
    { title: 'Gallery', path: '#gallery' },
    { title: 'F.A.Q', path: '#faq' },
    { title: 'Testimonial', path: '#testimonial' },
    { title: 'Contact', path: '#contact' },
];

const Header = (props) => {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle}>
            <List>
                {navItems.map((row, id) => (
                    <ListItem key={id} disablePadding>
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <ListItemText
                                component={NavHashLink}
                                sx={{ fontWeight: "600", fontSize: "18px", color: '#47536e' }}
                                to={row.path}
                                smooth="true"
                            >{row.title}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;


    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar component="nav" className='header'>
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: "block" }}>
                        <img src={Logo} alt='Par Logo' width={100} style={{ marginTop: "5px" }} />
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { sm: 'none' }, color: "#000" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((row, id) => (
                            <Button
                                key={id}
                                sx={{ color: '#47536e', fontWeight: "600", ml: 2 }}
                                component={NavHashLink}
                                to={row.path}
                                smooth="true"
                            >
                                {row.title}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </React.Fragment>
    )
}

Header.propTypes = {
    window: PropTypes.func,
};


export default Header