import React from 'react';
import Typography from '@mui/material/Typography';
import './Common.css'
import { Box } from '@mui/material';

export const Description = (props) => {
    return (
        <Typography className="description" sx={props.css}>{props.description}</Typography>
    );
};

export const Heading = (props) => {
    return (
        <Typography className="heading" sx={props.css}>
            {props.heading}
        </Typography>
    );
};

export const SectionTitle = (props) => {
    return (
        <Box className="section-title" sx={{ textAlign: "center" }}>
            <Typography className="heading" sx={props.css}> {props.heading}</Typography>
            <Typography className="description" sx={props.css}>{props.description}</Typography>
        </Box>
    );
};