import React, { useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AppleIcon from '@mui/icons-material/Apple';
import HeroImg from './../../Images/hero-img.png';
import Aos from "aos";
import "./Hero.css"

const Hero = () => {

    useEffect(function () {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <Box className="hero" id="hero" data-aos="fade-up">
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} data-aos="fade-right">
                        <img src={HeroImg} alt='Hero Images' width="100%" />
                    </Grid>
                    <Grid item xs={12} md={6} className="hero-content" data-aos="fade-left">
                        <Typography variant="h1">App landing page template</Typography>
                        <Typography variant="h2">Lorem ipsum dolor sit amet, tota senserit percipitur ius ut, usu et fastidii forensibus voluptatibus. His ei nihil feugait</Typography>
                        <Box className="btn-group">
                            <Button className='download-btn' variant="contained" startIcon={<PlayArrowIcon />}>Google Play</Button>
                            <Button className='download-btn' variant="contained" startIcon={<AppleIcon />} sx={{ ml: { xs: 2, sm: 3 } }}>App Store (Coming Soon)</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Hero