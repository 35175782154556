import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./Count.css"
import { Description, Heading } from "../Common/Common";

export default function Count(props) {
    const { label, number, duration } = props.data;
    // number displayed by component
    const [count, setCount] = useState("0");

    useEffect(() => {
        let start = 0;
        // first three numbers from props
        const end = parseInt(number.substring(0, 3));
        // if zero, return
        if (start === end) return;

        // find duration per increment
        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 1000;

        let timer = setInterval(() => {
            start += 1;
            setCount(String(start) + number.substring(3));
            if (start === end) clearInterval(timer);
        }, incrementTime);

        // dependency array
    }, [number.duration]);

    return (
        <Box sx={{ textAlign: "center", my: 3 }}>
            <Heading heading={"+ " + count} css={{ p: 0, fontSize: "30px !important" }} />
            <Heading heading={label} />
        </Box>
    );
}
