import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Heading, SectionTitle } from '../../Component/Common/Common';
import "./FAQ.css"

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<HelpOutlineOutlinedIcon className='faq-icon' />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#fff',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FAQ = () => {

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box className="section-bg faq" id="faq">
            <SectionTitle
                heading="Frequently Asked Questions"
                description="Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas."
            />
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="faq-container">
                <AccordionSummary aria-controls="panel1d-content" className="faq-title">
                    <Heading heading="What is Par Udhar Bahi Khata ?" />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='description'>1. Par is a digital ledger app for small business owners and their customers to facilitate the recording of credit/payment transactions digitally all over India.</Typography><br />
                    <Typography className='description'>2. Par equips merchants with an uncomplicated and secure means of administering credit and debit records for their business conducted on an account basis.</Typography><br />
                    <Typography className='description'>3. It digitises their transactions and documents payment, hence, diminishing the burden of maintaining paper account books aka Bahi Khata.</Typography><br />
                    <Typography className='description'>4. The ability to provide on-demand clarity of the merchant’s record to the shoppers is achievable for the first time.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="faq-container">
                <AccordionSummary aria-controls="panel2d-content" className="faq-title">
                    <Heading heading="What is a Ledger in Accounting ?" />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='description'>It is a record that is used to store bookkeeping entries. This entry can include different types of accounts like cash, receivables, expenses, credits & more.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="faq-container">
                <AccordionSummary aria-controls="panel3d-content" className="faq-title">
                    <Heading heading="What is the use of the PAR App ?" />
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className='description'>OkCredit is a new way to manage your ledger account book.</Typography><br />
                    <Typography className='description'>Just replace your old bahi khata register and never forget any entry.</Typography><br />
                    <Typography className='description'>Manage Apna khata book digitally to register statements & payments online with this easy-to-use mobile application.</Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default FAQ