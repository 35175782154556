import React, { useEffect } from 'react'
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FeaturesImg from '../../Images/features.svg';
import Aos from "aos";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Description, Heading, SectionTitle } from '../../Component/Common/Common'
import "./Features.css"

const Features = () => {

    useEffect(function () {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <Box className="section-bg features" id="features" data-aos="fade-up">
            <Container maxWidth='true'>
                <SectionTitle
                    heading="App Features"
                    description="Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas."
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} className='features-image' data-aos="fade-left">
                        <img src={FeaturesImg} alt='Features images' width="100%" />
                    </Grid>
                    <Grid item xs={12} md={8} className='features-content' data-aos="fade-right">
                        <Grid container spacing={2} className='features-list'>
                            <Grid item xs={12} md={6} className='features-item'>
                                <DescriptionOutlinedIcon />
                                <Box className="icon-box">
                                    <Heading heading="Reports and Account Statements" />
                                    <Description description="Access all your reports and statements on any device, anytime" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} className='features-item'>
                                <SafetyCheckOutlinedIcon />
                                <Box className="icon-box">
                                    <Heading heading="100% Safe and Secure" />
                                    <Description description="Online and local backup of data ensures 100% safety" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} className='features-item'>
                                <NotificationsActiveOutlinedIcon />
                                <Box className="icon-box">
                                    <Heading heading="Collection Reminders" />
                                    <Description description="Save the hassle of manual collections with timely reminders via free SMS and WhatsApp" />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} className='features-item'>
                                <DescriptionOutlinedIcon />
                                <Box className="icon-box">
                                    <Heading heading="Reports and Account Statements" />
                                    <Description description="Access all your reports and statements on any device, anytime" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Features