import React from 'react'
import Box from '@mui/material/Box';
import Header from './Component/Header/Header';
import Home from './Pages/Home/Home';
import Footer from './Component/Footer/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css'

function App() {
  return (
    <Router>
      <Box>
        <Header />
        <Box component="main">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Gallery from "./Section/Gallery/Gallery";

// export default function App() {
//   return (
//     <Gallery />
//   );
// }
