import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Description, Heading } from '../Common/Common';
import './Footer.css'

const Footer = () => {
    return (
        <Box className="footer" sx={{ pt: 4 }}>
            <Box className="footer-top" sx={{ p: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} className='footer-contact'>
                        <Heading heading="Payable & Recievable" />
                        <Description description="A108 Adam Street New York, NY 535022 United States" />
                        <p className='description' style={{ fontSize: "14px" }}>
                            <strong>Phone :</strong> +971 99999 99999<br />
                            <strong>Email :</strong> info@example.com<br />
                        </p>
                    </Grid>
                    <Grid item xs={12} md={3} className='footer-links'>
                        <Heading heading="Useful Links" />
                        <ul>
                            <li><ChevronRightIcon /> <Box>Home</Box></li>
                            <li><ChevronRightIcon /> <Box>About us</Box></li>
                            <li><ChevronRightIcon /> <Box>Services</Box></li>
                            <li><ChevronRightIcon /> <Box>Terms of service</Box></li>
                            <li><ChevronRightIcon /> <Box>Privacy policy</Box></li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={3} className='footer-links'>
                        <Heading heading="Our Services" />
                        <ul>
                            <li><ChevronRightIcon /> <Box>Home</Box></li>
                            <li><ChevronRightIcon /> <Box>About us</Box></li>
                            <li><ChevronRightIcon /> <Box>Services</Box></li>
                            <li><ChevronRightIcon /> <Box>Terms of service</Box></li>
                            <li><ChevronRightIcon /> <Box>Privacy policy</Box></li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={3} className="footer-links">
                        <Heading heading="Our Social Networks" />
                        <Description description="Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies" />
                        <Box className="social-links mt-3">
                            <Box className="social-media-item twitter"><TwitterIcon /></Box>
                            <Box className="social-media-item facebook"><FacebookIcon /></Box>
                            <Box className="social-media-item instagram"><InstagramIcon /></Box>
                            <Box className="social-media-item google-plus"><LinkedInIcon /></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer-bottom" sx={{ p: 3, bgcolor: "#fffaf2", color: "#000" }}>
                <Description description="© Copyright Payable & Recievable. All Rights Reserved" />
                <Description description="Designed by RABS Net Solutions Pvt. Ltd." />
            </Box>
        </Box >
    )
}

export default Footer